<template>
  <CRow class="d-flex justify-content-center">
    <CCol md="12" class="text-center text-black">
      <CCard>
        <CCardBody>
          <CRow>
            <CCol md="12" class="text-center">
              <h2 class="mt-3 font-weight-bold text-black">สร้างคิวอาร์โค้ด</h2>
              <h5 class="mt-3">สำหรับสั่งอาหาร และรอรับที่ร้านค้า</h5>
              <h6 class="text-description">
                คุณสามารถสร้างคิวอาร์โค้ด
                สำหรับให้ลูกค้าสแกนสั่งสินค้า และรอรับที่ร้านค้าได้
              </h6>
            </CCol>
          </CRow>
          <hr />
          <CRow>
            <CCol md="6">
              <p class="text-black font-weight-bold text-left">
                ดาวน์โหลดคิวอาร์โค้ด
              </p>

              <CJumbotron color="white" border-color="grey-200">
                <vue-qrcode
                  class="img-fluid"
                  :value="qrpickup"
                  style="width: 240px"
                />
                <br />
                <button class="btn btn-outline-dark btn-sm">
                  <small>
                    <CIcon name="cil-camera"></CIcon> ดาวน์โหลดคิวอาร์โค้ด
                  </small>
                </button>
              </CJumbotron>

              <h6 class="text-left font-weight-bold">แก้ไขข้อความ</h6>
              <span
                style="font-size: 12px; float: left"
                class="text-description"
              >
                แก้ไขข้อความที่ใช้แสดง
              </span>
              <br />

              <div class="form-group row">
                <div class="col-sm-12">
                  <textarea
                    class="text-black form-control"
                    v-model="message"
                    rows="5"
                  />
                </div>
              </div>
            </CCol>
            <CCol md="6" class="wrp">
              <p class="text-black font-weight-bold text-left">
                ตัวอย่างโปสเตอร์
              </p>
              <CJumbotron
                color="white"
                border-color="grey-200"
                class="col-md-12 mx-auto"
              >
                <h1 class="text-black font-weight-bold" style="font-size: 48px">
                  {{ shopName }}
                </h1>

                <br />

                <vue-qrcode
                  class="img-fluid"
                  :value="qrpickup"
                  style="width: 240px"
                />

                <br />
                <br />
                <h3 class="text-grey font-weight-bold">สแกน QR Code</h3>
                <p class="text-grey">{{ message }}</p>
                <br />

                <span>
                  <small> Powered by </small>
                  <img
                    alt=""
                    class="img-fluid"
                    :src="logo"
                    style="width: 20%"
                  />
                </span>
              </CJumbotron>
            </CCol>
          </CRow>
          <CButton
            @click="downloadPoster()"
            v-if="loadingButton === false"
            color="success"
            block
          >
            ดาวน์โหลดโปสเตอร์
          </CButton>
          <CButton
            disabled
            v-else-if="loadingButton === true"
            color="success"
            block
          >
            <CSpinner color="white" size="sm" /> ดาวน์โหลดโปสเตอร์
          </CButton>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from 'vuex'
import VueQrcode from 'vue-qrcode'

import crm from '@/services/crm'
import report from '@/services/report'
import pos from '@/services/pos'

export default {
  components: { VueQrcode },
  data() {
    return {
      message: 'ดูเมนู และสั่งสินค้า',
      logo: '../../logo.png',
      loadingButton: false,
      qrsilom: 'https://www.silompos.com/assets/images/line_qr.png',
      objectId: '',
      qrcode: '',
      qrpickup:
        'https://smartmenu-silomdev.web.app/pickup/menulists?uid=7x1beyFzYEh5zucG28FeCqmByYN2&shopObjectId=2003281538eu1NTWRjoXBTI99XccK6bV',
    }
  },
  computed: {
    ...mapGetters(['users', 'shops', 'shopObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopName() {
      if (this.shops.length > 0) {
        return this.shops[0].shopName
      } else {
        return ''
      }
    },
  },
  created() {},
  methods: {
    changeQRCode() {
      this.qrcode = ''
    },
    downloadPoster() {
      this.loadingButton = true

      let info = {
        shopName: this.shopName,
        tel: this.tel,
        website: this.website,
        facebook: this.facebook,
        lineadd: this.lineadd,
        message: this.message,
        qrcode: this.qrcode,
      }

      let html = util.exportPoster(info)

      let data = {
        ref: this.shopObjectId,
        timezone: 'Asia/Bangkok',
        html: html,
      }
      let name = 'poster' + '.pdf'

      report({
        method: 'post',
        url: '/print/v1.0/' + this.uid + '/htmltopdf/a4',
        data: data,
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        this.loadingButton = false
      })
    },
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Mitr:wght@300&display=swap');
@font-face {
  font-family: 'Mitr', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Mitr:wght@300&display=swap');
}

.wrp {
  font-family: 'Mitr', sans-serif;
}

.remove-image {
  display: none;
  position: absolute;
  top: 30px;
  right: 5px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #393939;
  border: 1px solid #fff;
  color: #fff;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
}
.remove-image:active {
  background: #e54e4e;
}

.pointer {
  cursor: pointer;
}
</style>
