import { render, staticRenderFns } from "./PickUp.vue?vue&type=template&id=7a002968&scoped=true"
import script from "./PickUp.vue?vue&type=script&lang=js"
export * from "./PickUp.vue?vue&type=script&lang=js"
import style0 from "./PickUp.vue?vue&type=style&index=0&id=7a002968&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a002968",
  null
  
)

export default component.exports