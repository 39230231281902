import axios from 'axios'
import util from '../util/asymmetric'

let token = util.asymmetric()

export default axios.create({
  baseURL: process.env.VUE_APP_CRM_API_URL,
  //baseURL : 'http://localhost:5001/crm',
  headers: {
    token: token,
  },
})
